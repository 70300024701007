import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { oktaAuthConfig } from './okta-authentication-manager';
import AuthRoutes from './auth-routes';
import TransitionRadar from './components/transition-radar';

function AuthContainer(props: any) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [authError, setAuthError] = useState(false);
  const { callback } = props;
  const navigate = useNavigate();

  /********** OKTA Authentication *********/
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  /***************OKTA Authentication ********/

  const handleAuthComplete = (
    authSuccessful: boolean,
    permissions: any,
    userName: string,
  ) => {
    if (authSuccessful) {
      setTimeout(() => {
        setIsAuthenticating(false);
      }, 900);
      callback({ ...permissions }, userName);
    } else {
      setAuthError(true);
    }
  };

  return (
    <Security oktaAuth={oktaAuthConfig} restoreOriginalUri={restoreOriginalUri}>
      <AuthRoutes setApplicationPermissions={handleAuthComplete} />
      <TransitionRadar
        transitionError={authError}
        transitionActive={isAuthenticating}
      />
    </Security>
  );
}

export default AuthContainer;
