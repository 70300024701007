import React from 'react';
import AuthContainer from './auth-container';
import { BrowserRouter } from 'react-router-dom';

const Root = (props: any) => {
  return (
    <BrowserRouter>
      <AuthContainer {...props} />
    </BrowserRouter>
  );
};

export default Root;
