import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';
import RequestAccessModal from '../request-access-modal';
import Text from './text';
import Radar from './radar';

import { Button } from '@opsdti-global-component-library/amgen-design-system';
import './styles.scss';

interface TransitionWaterfallProps {
  transitionActive: boolean;
  transitionError: boolean;
}

type AdminMessage = {
  action: 'access request successful';
};

const SplashLogo = (props: TransitionWaterfallProps) => {
  const [requestAccessModalOpen, setRequestAccessModalOpen] = useState(false);
  const { transitionActive, transitionError } = props;
  const contentRoot =
    document.getElementsByTagName('body')[0] ?? ({} as Element);

  const [accessRequestSuccessful, setAccessRequestSuccessful] = useState(false);

  function handleRequestAccessClick() {
    setRequestAccessModalOpen(true);

    const abortController = new AbortController();
    window.addEventListener(
      'message',
      (event: MessageEvent<AdminMessage>) => {
        if (
          event.origin !== window.location.origin ||
          event.data.action !== 'access request successful'
        ) {
          return;
        }
        abortController.abort();
        setAccessRequestSuccessful(true);
      },
      { signal: abortController.signal },
    );
  }

  return createPortal(
    <React.Fragment>
      <AnimatePresence>
        {transitionActive && (
          <React.Fragment>
            <motion.div
              key="backdrop-content"
              className="rtsensing-auth-transition-content-container"
              initial={{ background: 'rgba(255,255,255,0)' }}
              animate={{ background: 'rgba(255,255,255,0)' }}
              exit={{
                transition: { delay: 0.25 },
              }}
            >
              {!transitionError && (
                <motion.div className="rtsensing-auth-splash-container">
                  <Radar />
                  <Text />
                </motion.div>
              )}
              {transitionError && (
                <motion.div
                  key="error-message"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="waterfall-error"
                >
                  <p className="waterfall-error-message waterfall-error-message--title">
                    Unable to Sign In!
                  </p>
                  <p className="waterfall-error-message waterfall-error-message--paragraph">
                    We are unable to sign you in at this time - Please try again
                    or report your issue for assistance
                  </p>
                  <div className="waterfall-error-options">
                    <Button
                      text="Report Error"
                      type="secondary"
                      onClick={() => setRequestAccessModalOpen(true)}
                    />
                    <Button
                      text="Try Again"
                      type="secondary"
                      onClick={() => window.location.reload()}
                    />
                  </div>
                </motion.div>
              )}
            </motion.div>
          </React.Fragment>
        )}
      </AnimatePresence>

      {requestAccessModalOpen && (
        <RequestAccessModal
          opened={requestAccessModalOpen}
          onRequestAccessModalClosed={() => setRequestAccessModalOpen(false)}
          accessRequestSuccessful={accessRequestSuccessful}
        />
      )}
    </React.Fragment>,
    contentRoot,
  );
};

export default SplashLogo;
