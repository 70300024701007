import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

//OKTA
import { LoginCallback, useOktaAuth } from '@okta/okta-react';

// Custom Okta
import { RequiredAuth } from './secure-route';
import {
  oktaAuthConfig,
  setEventOnExpired,
  fetchPermission,
} from './okta-authentication-manager';
import Logout from './logout';
import { AuthPermissions } from './types';
import { AuthContext } from './auth-context';
import { useContext } from 'react';

type PropType = {
  setApplicationPermissions: (
    authSuccessful: boolean,
    permissions: any,
    userName: string,
  ) => void;
};

const AuthRoutes: React.FC<PropType> = props => {

  const { setApplicationPermissions } = props;
  const { oktaAuth, authState } = useOktaAuth();
  const [accessData, setAccessData] = useState<any>(null);
  const [initialPermissions, setInitialPermissions] =
    useState<AuthPermissions | null>(null);

  /********** RENEW TOKEN EVERY 15 MINUTES  *********/
  useEffect(() => {
    const renewTokenInterval = setInterval(() => {
      oktaAuthConfig.tokenManager.renew('accessToken');
      oktaAuthConfig.tokenManager.renew('idToken');
    }, 900000); // Every 15min

    return () => clearInterval(renewTokenInterval);
  }, []);

  /********** AUTOMATICALLY RENEW TOKEN ON EXPIRATION *********/
  useEffect(() => {
    setEventOnExpired(oktaAuthConfig);

    return () => oktaAuthConfig.tokenManager.off('expired');
  }, []);

  /********** OBTAIN AUTHORIZATION *********/
  useEffect(() => {
    const initialPermissionFetch = async () => {
      const accessToken = await oktaAuthConfig.tokenManager.get('accessToken');
      const idToken = await oktaAuthConfig.tokenManager.get('idToken');
      console.log(idToken?.claims?.preferred_username.split("@")[0], 'Permissions:', accessToken.accessToken);
      const response = await fetchPermission(idToken?.claims?.preferred_username.split("@")[0], accessToken.accessToken);

      if (idToken) {

        if (response?.permissions && response.userName) {
          if (localStorage.getItem("sensing-okta-token") === null) {
            setAccessData({
              'sensing-okta-token': {
                accessToken,
                idToken,
              }
            });
          }
          setApplicationPermissions(
            true,
            response.permissions,
            response.userName,
          );
          setInitialPermissions({ ...response.permissions });
        } else {
          setApplicationPermissions(false, {}, 'USER_NOT_FOUND');
        }
      }
    };

    const token = oktaAuth.getAccessToken();
    if (token && authState && !initialPermissions) {
      initialPermissionFetch();
    }
  }, [oktaAuth, authState, initialPermissions, setApplicationPermissions]);

  return (
    <AuthContext.Provider value={{ accessData, setAccessData }}>
      <Routes>
        <Route path="*" element={<RequiredAuth />}>
          <Route path="" element={<></>} />
        </Route>
        <Route path="/implicit/callback" element={<LoginCallback />} />
        <Route path="/auth/logout" element={<Logout />} />
      </Routes>
    </AuthContext.Provider>
  );
};

export default AuthRoutes;
